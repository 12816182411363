<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-sm table-striped" id="product_list">
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Details</th>
            <th>Threshold Limit(Warehouse|Hub)</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in products" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.unit }}</td>
            <td>{{ product.unit_price }}</td>
            <td>{{ product.product_detail || 'N/A' }}</td>
            <td>{{ product.warehouse_threshold_limit }} | {{ product.hub_threshold_limit }}</td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="products.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      products: [],
      product: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    getProducts() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/products')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.products = response.data.products
          setTimeout(function() {
            $('#product_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
